import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { container, actionBar, button, wrapper } from './html-error-message.module.scss';

import SEO from '../components/seo';
import Title from '../components/atoms/title';
import Button from '../components/atoms/button';
import Main from '../layouts/main';

export default function HtmlErrorMessage({ pageContext }) {
    const { statusCode, message, seoMessage } = pageContext;

    return (
        <>
            <Main className={wrapper}>
                <div className={container}>
                    <SEO title={seoMessage} />
                    <Title>{message ? message : 'Wystąpił problem'}</Title>
                    <p>Kod błędu: {statusCode}</p>
                    <div className={actionBar}>
                        <Link to="/">
                            <Button className={button}>Strona główna</Button>
                        </Link>
                        <Link to="/kontakt/">
                            <Button color="blank" className={button}>
                                Zgłoś błąd
                            </Button>
                        </Link>
                    </div>
                </div>
            </Main>
        </>
    );
}

HtmlErrorMessage.propTypes = {
    statusCode: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
    seoMessage: PropTypes.string.isRequired,
};
